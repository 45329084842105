import React, { useState } from "react";
import { Link } from "react-router-dom";
import whatsapp from "./wha1.png";
import Hamburger from "hamburger-react";
const Header = () => {
  const [isOpen, setOpen] = useState(false);

  const [message, setMessage] = useState("Hello There");
  const openWhatsApp = () => {
    const phoneNumber = "+917841804871";

    const whatsappLink = `https://wa.me/${phoneNumber}?text=${message}`;

    window.open(whatsappLink);
  };
  const handleToggle = () => {
    setOpen(!isOpen);
  };
  return (
    <nav className="navbar navbar-expand-lg main_menu main_menu_3">
      <div className="container container_large">
        <Link to="/home" className="navbar-brand">
          <img
            src="assets/images/logo/logonobg.png"
            alt="siddhivinayak"
            className="img-fluid"
          />
        </Link>
        {/* <button
          onClick={() => setMenu(!menu)}
          // className="navbar-toggler"
          // type="button"
          // data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          // aria-expanded="false"
          // aria-label="Toggle navigation"
        >
          <Hamburger />
        </button> */}
        <div className="mobilemenu">
          <Hamburger toggled={isOpen} toggle={handleToggle} />
        </div>

        <div className="collapse navbar-collapse desktopmenu">
          <ul className="navbar-nav m-auto">
            <li className="nav-item">
              <Link
                style={{ color: "#000" }}
                to="/home"
                className="nav-link active"
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link
                style={{ color: "#000" }}
                to="/aboutus"
                className="nav-link"
                href="#"
              >
                About Us
              </Link>
            </li>
            <li className="nav-item">
              <Link
                style={{ color: "#000" }}
                to="/projects"
                className="nav-link"
              >
                Projects{" "}
              </Link>
            </li>

            <li className="nav-item">
              <Link
                style={{ color: "#000" }}
                to="/contactus"
                className="nav-link"
                href="#"
              >
                Contact Us
              </Link>
            </li>
          </ul>
          <ul className="menu_right d-flex align-items-center">
            <li onClick={openWhatsApp}>
              <a className="user_icon" style={{ color: "#000" }} href="#">
                <span>
                  {" "}
                  <img src={whatsapp} alt="user" />
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="mobilemenu">
        {" "}
        {isOpen && (
          <div onClick={handleToggle}>
            <ul
              className="navbar-nav m-auto"
              style={{ width: "205px", height: "100vh" }}
            >
              <li className="nav-item">
                <Link to="/home" className="nav-link ">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/aboutus" className="nav-link">
                  About Us
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/projects" className="nav-link">
                  Projects{" "}
                </Link>
              </li>

              <li className="nav-item">
                <Link to="/contactus" className="nav-link" href="#">
                  Contact Us
                </Link>
              </li>
              <li onClick={openWhatsApp}>
                <a
                  className="user_icon"
                  style={{ color: "#fff", marginLeft: "30px" }}
                  href="#"
                >
                  <span>
                    {" "}
                    <img src={whatsapp} alt="user" />
                  </span>
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Header;
