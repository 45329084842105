import React, { useState , useRef } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from '@emailjs/browser';

const Footer = () => {
  const [name, setName] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [contactno, setContactNo] = useState("");
  const [message, setMessage] = useState("");
  const [loader , setLoader] = useState(false);
  const form = useRef();


  const sendEmail = (e) => {
    e.preventDefault();
    setLoader(true);
    if (!email || !name || !contactno || !message) {
      toast.error("Please fill out all fields", { autoClose: 1500 });
      setLoader(false);

      return;

    }
  
    emailjs
      .sendForm("service_uxggv9o", "template_ebsvpkn", form.current , {publicKey: 'wZs7KW8Zux3WrlXsA'})
      .then(
        () => {
          toast.success("Email sent successfully!", { autoClose: 1500 });
          setName("");
          setEmail("");
          setContactNo("");
          setMessage("");
          
          form.current.reset(); // Reset the form
          setLoader(false);
        },
        (error) => {
          console.error("Failed to send email:", error);
          toast.error("Failed to send email. Please try again.", { autoClose: 1500 });
          setLoader(false);
        }
      );
  };
  



  return (
    <>
      {" "}
      <ToastContainer theme="dark" />
      <footer className="footer">
        <div className="container container_large">
          <div className="row" style={{ padding: "0 30px" }}>
            <div className="col-xl-7">
              <div className="footer_left pt_100 pb_80">
                <div className="row justify-content-between">
                  <div
                    className="col-xl-5 col-sm-10 col-md-4 wow fadeInLeft"
                    data-wow-duration="2s"
                  >
                    <div className="footer_description">
                      <a
                        className="footer_logo"
                        style={{ height: "78px" }}
                        href="index_3.html"
                      >
                        <img
                          src="assets/images/logo/logonobg.png"
                          alt="logo"
                          className="img-fluid w-100"
                          style={{ height: "250px" }}
                        />
                      </a>
                      <p className="mt-1">
                        {" "}
                        <i
                          class="fa fa-phone"
                          style={{
                            fontSize: "15px",
                            transform: "rotate(116deg)",
                            marginRight: "10px",
                          }}
                        ></i>
                        +91 7841804871
                      </p>
                      <p className="mt-1">
                        {" "}
                        <i
                          class="fa fa-envelope"
                          style={{
                            fontSize: "15px",
                            marginRight: "10px",
                          }}
                        ></i>
                        siddhivinayakrealities23@gmail.com{" "}
                      </p>
                      <p
                        className="mt-1"
                        style={{ display: "flex", alignItems: "" }}
                      >
                        <i
                          class="fa fa-address-card-o"
                          style={{ fontSize: "15px", marginRight: "10px" }}
                        ></i>
                        <p style={{ marginTop: "-5px" }}>
                          {" "}
                          202 (2nd) Jalaram mangalam, Hingna Rd, opp. tactor
                          company, Nagpur, Maharashtra 440016
                        </p>
                      </p>

                      <ul className="d-flex flex-wrap">
                        <li>
                          <a href="#">
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="col-xl-3 col-sm-6 col-md-4 wow fadeInLeft"
                    data-wow-duration="1.5s"
                  >
                    <div className="footer_link">
                      <h4>Important Links</h4>
                      <ul>
                        <li>
                          <Link to="/home">Home</Link>
                        </li>
                        <li>
                          <Link to="/aboutus">About Us</Link>
                        </li>
                        <li>
                          <Link to="/projects">Projects</Link>
                        </li>

                        <li>
                          <Link to="/contactus">Contact Us</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-5 wow fadeInRight" data-wow-duration="1.5s">
              <div className="footer_right">
                <h4>Get in Touch with Us</h4>
                <form ref={form} onSubmit={sendEmail}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="footer_form_input">
                        <input
                          type="text"
                          placeholder="First name"
                          name="name"
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="footer_form_input">
                        <input
                          type="text"
                          placeholder="Last name"
                          onChange={(e) => setLname(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="footer_form_input">
                        <input
                          type="email"
                          placeholder="Email"
                          name="email"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="footer_form_input">
                        <input
                          type="number"
                          placeholder="Phone number"
                          name="number"
                          onChange={(e) => setContactNo(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-xl-12">
                      <div className="footer_form_input">
                        <textarea
                          rows="2"
                          placeholder="Message"
                          name="message"
                          onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-xl-12">
                      {
                        loader ?    <div className="spinner-container">
                        <div className="spinner"></div>
                      </div>
                        :
                        <button
                        type="submit"
                        style={{ marginBottom: "35px" }}
                        className="common_btn footer_btn"
                      >
                        Send Message
                      </button>
                      }
                     
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="copy_right wow fadeInUp" data-wow-duration="1.5s">
          <div className="container container_large">
            <div className="row">
              <div className="col-xl-12">
                <div className="copyright_area d-flex flex-wrap justify-content-center">
                  <p>© 2024 All Rights Reserved by Siddhivinayak Realities</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>{" "}
    </>
  );
};

export default Footer;
