import React, { useEffect, useState , useRef } from "react";
import shrinagariphase1 from "./images/shrinagariphase1.jpg";
import swapnanagari from "./images/swapnanagari.jpg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from '@emailjs/browser';


import ektanagari from "./images/ektanagari.jpg";
import shrinagariphase2 from "./images/shrinagariphase2.jpg";
import shrinagariphase3 from "./images/shrinagariphase3.jpg";
import madhavnagari from "./images/madhavnagari.jpg";
import F2images from "./images/F2images.jpg";
import F9images from "./images/F9images.jpg";
import F7images from "./images/F9images.jpg";
import F4images from "./images/F9images.jpg";
import salesImg1 from '../../sections/meettherealityprofessionals/img/ankush.jpeg'
import salesImg2 from '../../sections/meettherealityprofessionals/img/ganshyam (1).jpeg'
import salesImg3 from '../../sections/meettherealityprofessionals/img/prof3.jpg'
import salesImg4 from '../../sections/meettherealityprofessionals/img/vicky.jpeg'
import salesImg5 from '../../sections/meettherealityprofessionals/img/rahul.jpeg'
import salesImg6 from '../../sections/meettherealityprofessionals/img/professional2.jpg'




import { useParams } from "react-router-dom";

const Projectsdetails = () => {
  const { id } = useParams();
  console.log("id", id);
  const contonj = [
    {
      id: "1",
      imgpath: F9images,
      imgname: "Shri Sai Nagari",
      phase: "Phase 1",
      address: `AT Mouza Panjarakate, Tah-Katol, Dist-Nagpur`,
      salesName:"Ankush Pawar",
      salesImages:salesImg1 ,
      description: `Discover the essence of comfortable living at our prime location in Mouza Panjarakate, Tah-Katol Dist-Nagpur. With picturesque surroundings and contemporary amenities, this property promises a lifestyle of convenience and natural beauty`,
      description1: `Nestled in the serene locale of  Mouza Panjarakate, our property at Tah-Katol, Dist-Nagpur, offers a perfect blend of tranquility and modern living, providing an idyllic retreat in the heart of nature.`,
      location: `https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3721.419561240776!2d78.650847!3d21.135695!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDA4JzA4LjUiTiA3OMKwMzknMDMuMSJF!5e0!3m2!1sen!2sin!4v1716631713346!5m2!1sen!2sin`,
    },
    {
      id: "2",
      imgpath: swapnanagari,
      imgname: "Swapna Nagari",
      phase: "Phase 1",
      salesName:"Ghanshyam Dhole",
      salesImages:salesImg2 ,
      address: `AT Mouza Sonegaon (Kondhali), Tah-Katol, Dist-Nagpur`,
      description1: `Nestled in the serene locale of Mouza Sonegaon (Kondhali), our property at Tah-Katol, Dist-Nagpur, offers a perfect blend of tranquility and modern living, providing an idyllic retreat in the heart of nature.`,

      description: `Discover the essence of comfortable living at our prime location in Mouza Sonegaon (Kondhali), Tah-Katol Dist-Nagpur. With picturesque surroundings and contemporary amenities, this property promises a lifestyle of convenience and natural beauty.`,

      location: `https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3721.1324911553043!2d78.627269!3d21.147125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDA4JzQ5LjciTiA3OMKwMzcnMzguMiJF!5e0!3m2!1sen!2sin!4v1716631812564!5m2!1sen!2sin`,
    },
    {
      id: "3",
      imgpath: F4images,
      imgname: "Ekta Nagari",
      phase: "Phase 1",
      salesName:"Nashim Alam",
      salesImages:salesImg3 ,
      address: `AT Mouza Katepanjara, Tah-Katol, Dist-Nagpur`,
      description1: `Nestled in the serene locale of Mouza Katepanjara, our property at Tah-Katol, Dist-Nagpur, offers a perfect blend of tranquility and modern living, providing an idyllic retreat in the heart of nature.`,

      description: `Discover the essence of comfortable living at our prime location in Mouza Katepanjara, Tah-Katol Dist-Nagpur. With picturesque surroundings and contemporary amenities, this property promises a lifestyle of convenience and natural beauty.`,

      location: `https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3721.5108501244786!2d78.654455!3d21.132058999999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDA3JzU1LjQiTiA3OMKwMzknMTYuMCJF!5e0!3m2!1sen!2sin!4v1716631607725!5m2!1sen!2sin`,
    },
    {
      id: "4",
      imgpath: F7images,
      imgname: "Shri Nagari",
      phase: "Phase 2",
      salesName:"Vicky Niwal",
      salesImages:salesImg4,
      address: `AT Mouza Khapa (Sonar), Tah-Katol, Dist-Nagpur`,
      description1: `Nestled in the serene locale of Mouza Khapa (Sonar), our property at Tah-Katol, Dist-Nagpur, offers a perfect blend of tranquility and modern living, providing an idyllic retreat in the heart of nature.`,

      description: `Discover the essence of comfortable living at our prime location in  Mouza Khapa (Sonar), Tah-Katol Dist-Nagpur. With picturesque surroundings and contemporary amenities, this property promises a lifestyle of convenience and natural beauty.`,

      location: `https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3720.8701308694176!2d78.61837!3d21.157566000000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDA5JzI3LjIiTiA3OMKwMzcnMDYuMSJF!5e0!3m2!1sen!2sin!4v1716631861883!5m2!1sen!2sin`,
    },
    {
      id: "5",
      imgpath: shrinagariphase3,
      imgname: "Shri Nagari",
      phase: "Phase 3",
      salesName:"Rahul patle",
      salesImages:salesImg5 ,
      address: `AT Mouza Khapasonar, Tah-Katol, Dist-Nagpur`,
      description: `Discover the essence of comfortable living at our prime location inMouza Khapasonar, Tah-Katol, Dist-Nagpur. With picturesque surroundings and contemporary amenities, this property promises a lifestyle of convenience and natural beauty.`,
      description1: `Nestled in the serene locale of inMouza Khapasonar, our property at Tah-Katol, Dist-Nagpur, offers a perfect blend of tranquility and modern living, providing an idyllic retreat in the heart of nature.`,

      location: `https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3720.774262289661!2d78.616044!3d21.161379999999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjHCsDA5JzQxLjAiTiA3OMKwMzYnNTcuOCJF!5e0!3m2!1sen!2sin!4v1716631923498!5m2!1sen!2sin`,
    },
    {
      id: "6",
      imgpath: F2images,
      imgname: "Madhav Nagari",
      phase: "Phase 1",
      salesName:"Rahul Bagde",
      salesImages:salesImg6 ,
      address: `AT Mouza Salai Khurdh, Tah-Katol, Dist-Nagpur`,
      description1: `Nestled in the serene locale of Mouza Salai Khurdh, our property at Tah-Katol, Dist-Nagpur, offers a perfect blend of tranquility and modern living, providing an idyllic retreat in the heart of nature.`,

      description: `Discover the essence of comfortable living at our prime location in Mouza Salai Khurdh, Tah-Katol, Dist-Nagpur. With picturesque surroundings and contemporary amenities, this property promises a lifestyle of convenience and natural beauty.`,
      location: `https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d119071.32689120273!2d79.00628360163587!3d21.1532353741738!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1709805869905!5m2!1sen!2sin`,
    },
  ];
  const selectedProjects = contonj.filter((project) => project.id === id);

  console.log("selectedProjects", selectedProjects[0]);

  useEffect(() => {
    const isPageReloaded =
      window.performance && window.performance.navigation.type === 1;

    if (isPageReloaded) {
      window.location.href = `/projects`;
    }
  }, []);

  const [name, setName] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [loader , setLoader] = useState(false);
  const form = useRef();

  const [contactno, setContactNo] = useState("");
  const [message, setMessage] = useState("");



  const handleImageClick = () => {
    setShowFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    setShowFullScreen(false);
  };


  const enquiry=()=>{

    const phoneNumber = "+917841804871";

    const whatsappLink = `https://wa.me/${phoneNumber}`;
    window.open(whatsappLink);

  }


  
  const sendEmail = (e) => {
    e.preventDefault();
    console.log("call")
    setLoader(true);
    if (!email || !name || !contactno || !message) {
      toast.error("Please fill out all fields", { autoClose: 1500 });
      setLoader(false);

      return;

    }
  
    emailjs
      .sendForm("service_uxggv9o", "template_ebsvpkn", form.current , {publicKey: 'wZs7KW8Zux3WrlXsA'})
      .then(
        () => {
          toast.success("Email sent successfully!", { autoClose: 1500 });
          setName("");
          setEmail("");
          setContactNo("");
          setMessage("");
          
          form.current.reset(); // Reset the form
          setLoader(false);
        },
        (error) => {
          console.error("Failed to send email:", error);
          toast.error("Failed to send email. Please try again.", { autoClose: 1500 });
          setLoader(false);
        }
      );
  };
  



  return (
    <>
      <ToastContainer theme="dark" />

      <section className="property_details pt_120 xs_pt_100 pb_105 xs_pb_85">
        <div className="container">
          <div className="row wow fadeInUp" data-wow-duration="1.5s"></div>
          <div className="row mt_50">
            <div className="col-lg-8">
              <div
                className="single_property_details wow fadeInUp"
                data-wow-duration="1.5s"
              >
                <div className=" d-flex flex-wrap justify-content-between">
                  <h4>
                    The Art of Living: Showcasing Properties Crafted for
                    Perfection
                  </h4>
                  <ul className="property_details_share d-flex flex-wrap">
                    <li>
                      <a href="#">
                        <i className="fas fa-share-alt"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fas fa-print"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="property_details_address d-flex flex-wrap justify-content-between">
                  <ul className="d-flex flex-wrap">
                    <li>
                      <i className="fas fa-map-marker-alt"></i>
                      {selectedProjects[0].address}
                    </li>
                    <li>
                      <i className="far fa-clock"></i>1 months ago
                    </li>
                  </ul>
                  <h3>*******</h3>
                </div>
              </div>
              <div
                className="single_property_details mt_25 wow fadeInUp"
                data-wow-duration="1.5s"
              >
                <h4>Description</h4>
                <p className=" property_dtls_decription">
                  {selectedProjects[0].description}
                </p>
                <p className="property_dtls_decription pt_15">
                  Phasellus sed pellentesque neque, sit amet porta quam.
                  {selectedProjects[0].description1}.
                </p>
              </div>
              <div
                className="single_property_details mt_25 wow fadeInUp"
                data-wow-duration="1.5s"
              >
                <h4>Property Details</h4>
                <ul className=" property_apartment_details d-flex flex-wrap mt_10">
                  <li>
                    <p>
                      Property Location<span> {selectedProjects[0].address}</span>
                    </p>
                  </li>
                  <li>{/* <p>Rooms:<span>5</span></p> */}</li>
                  <li>
                    <p>
                      Property Status:<span>For Sale</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      Property Price:<span className="enquirysp" onClick={enquiry}>Click Here</span>
                    </p>
                  </li>
                  <li>{/* <p>Garages:<span>2</span></p> */}</li>
                  <li>{/* <p>Bedrooms:<span>5</span></p> */}</li>
                  <li>
                    <p>
                      Property Type:<span>Garden House</span>
                    </p>
                  </li>
                  <li>{/* <p>Baths:<span>5</span></p> */}</li>
                  <li>{/* <p>Originating Year:<span>2022</span></p> */}</li>
                </ul>
                <div className="property_facilities">
                  <h4>Facilities</h4>
                  <ul className="d-flex flex-wrap">
                    <li>Parking</li>
                    <li>Accessibillity</li>
                    <li>Security</li>
                    <li>Temple</li>

                    <li>gym</li>
                    <li>Parking</li>
                    <li>Cctv</li>
                    <li>Garden</li>
                  </ul>
                </div>
              </div>
              <div
                className="single_property_details mt_25 wow fadeInUp"
                data-wow-duration="1.5s"
              >
                <h4>Our Plans</h4>
                <div className=" apertment_layout">
                  <img
                    src={selectedProjects[0].imgpath}
                    alt="img"
                    className="img-fluid w-100"
                    onClick={handleImageClick}
                    style={{ cursor: 'pointer' }}
                  />
                </div>
              </div>
              <div
                className="single_property_details mt_25 wow fadeInUp"
                data-wow-duration="1.5s"
              >
                <h4>Map Location</h4>
                <div className=" apertment_map">
                  <iframe
                    src={
                      selectedProjects[0].location &&
                      selectedProjects[0].location
                    }
                    width="600"
                    height="450"
                    style={{ border: "0" }}
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
              {/* <div className="single_property_details mt_25 wow fadeInUp" data-wow-duration="1.5s">
                <h4>Property Video</h4>
                <div className=" apertment_video">
                    <iframe src="https://www.youtube.com/embed/ec_fXMrD7Ow?si=E-z18uXLa_wbsPXg"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen></iframe>
                </div>
            </div> */}
              <div
                className="single_property_details mt_25 wow fadeInUp"
                data-wow-duration="1.5s"
              >
                <h4>Customer Reviews</h4>

                <div className="apartment_review_area">
                  <div className="single_review">
                    <div className="single_review_img">
                      <img
                        src="https://i.pinimg.com/originals/7d/34/d9/7d34d9d53640af5cfd2614c57dfa7f13.png"
                        alt="img"
                        className="img-fluid w-100"
                        style={{ backgroundColor: "white" }}
                      />
                    </div>
                    <div className="single_review_text">
                      <h3>
                        Piyush Sharma
                        <span>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </span>
                      </h3>
                      <h6>March 25, 2024</h6>
                      <p>
                        I had a fantastic experience . They not only understood
                        my requirements but also provided valuable insights. The
                        property I bought is exactly what I was looking for.
                        Great job!".
                      </p>
                    </div>
                  </div>
                  <div className="single_review">
                    <div className="single_review_img">
                      <img
                        src="https://i.pinimg.com/originals/7d/34/d9/7d34d9d53640af5cfd2614c57dfa7f13.png"
                        alt="img"
                        className="img-fluid w-100"
                        style={{ backgroundColor: "white" }}
                      />
                    </div>
                    <div className="single_review_text">
                      <h3>
                        abrar khan
                        <span>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </span>
                      </h3>
                      <h6>March 24, 2024</h6>
                      <p>
                        Kudos to the team at DreamHomes! They helped me find the
                        perfect apartment within my budget. Professionalism,
                        transparency, and a hassle-free experience. I'm a happy
                        homeowner now..
                      </p>
                    </div>
                  </div>
                  <div className="single_review">
                    <div className="single_review_img">
                      <img
                        src="https://i.pinimg.com/originals/7d/34/d9/7d34d9d53640af5cfd2614c57dfa7f13.png"
                        style={{ backgroundColor: "white" }}
                        alt="img"
                        className="img-fluid w-100"
                      />
                    </div>
                    <div className="single_review_text">
                      <h3>
                        Rajat Saha
                        <span>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </span>
                      </h3>
                      <h6>February 24, 2024</h6>
                      <p>
                        I recently purchased a beautiful home through
                        Sidhi-vinayak realState. The entire process was smooth,
                        and the team was incredibly supportive. I'm delighted
                        with my new property and highly recommend their
                        services.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {showFullScreen && (
                <div className="full-screen-container" onClick={handleCloseFullScreen}>
                  <div className="full-screen-content">
                    <img src={selectedProjects[0].imgpath} alt="img" className="full-screen-image" />
                    <span className="close-icon" onClick={handleCloseFullScreen}>&times;</span>
                  </div>
                </div>
              )}
              {/* <div className="single_property_details details_apertment_form mt_25 wow fadeInUp"
                data-wow-duration="1.5s">
                <h4>Leave a Review</h4>
                <form action=" #" className="apertment_form">
                    <div className="row">
                        <div className="col-xl-6">
                            <div className="apertment_form_input">
                                <label>Name *</label>
                                <input type="text" placeholder="Enter your Name" />
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="apertment_form_input">
                                <label>Email *</label>
                                <input type="email" placeholder="Enter your Email" />
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <div className="apertment_form_input">
                                <label>Rating</label>
                                <ul className="d-flex flex-wrap">
                                    <li>
                                        <i className="fas fa-star"></i>
                                    </li>
                                    <li>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                    </li>
                                    <li>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                    </li>
                                    <li>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                    </li>
                                    <li>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                        <i className="fas fa-star"></i>
                                    </li>
                                </ul>
                                <textarea rows="6" placeholder="Enter Massage"></textarea>
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <div className="form-check blog_checkbox">
                                <input className="form-check-input" type="checkbox" id="flexCheckDefault"  />
                                <label className="form-check-label" for="flexCheckDefault">Save my name, email, and
                                    website in this browser for the next time I comment.</label>
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <a className="common_btn" href="#">Submit Review</a>
                        </div>
                    </div>
                </form>
            </div> */}
            </div>
            <div className="col-lg-3" style={{ marginLeft: "9px" }}>
              <div className="sticky_sidebar">
                <div className="property_details_sidebar">
                  <h4>Schedule a Tour</h4>
                  <form  ref={form} onSubmit={sendEmail} className="schedule_form">
                    <div className="row">
                      <div className="col-lg-12 col-md-6">
                        <div className="schedule_input">
                          <input
                            type="date"
                            placeholder="Date"
                            name="date"
                            onChange={(e) => setDate(e.target.value)}
                          />
                        </div>
                      </div>
                      {/* <div className="col-lg-12 col-md-6">
                        <div className="schedule_input">
                          <input type="text" placeholder="Time"   />
                        </div>
                      </div> */}
                      <div className="col-lg-12 col-md-6">
                        <div className="schedule_input">
                          <input
                            type="text"
                            placeholder="Name"
                            name="name" 
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-6">
                        <div className="schedule_input">
                          <input
                            type="text"
                            placeholder="Phone"
                            name='number'
                            onChange={(e) => setContactNo(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="schedule_input">
                          <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="schedule_input">
                          <textarea
                            rows="5"
                            name="message"
                            placeholder="Message"
                            onChange={(e) => setMessage(e.target.value)}
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-xl-12">
                      {
                        loader ?    <div className="spinner-container">
                        <div className="spinner"></div>
                      </div>
                        :
                        <button
                        type="submit"
                        style={{ marginBottom: "35px" }}
                        className="common_btn footer_btn"
                      >
                        Send Message
                      </button>
                      }
                     
                    </div>
                    </div>
                  </form>
                </div>
                <div className="sales_executive">
                  <a href="#" className="sales_executive_img">
                    <img
                      src= {selectedProjects[0].salesImages}
                      alt="img"
                      className="img-fluid w-100"
                    />
                  </a>
                  <a href="agent_details.html" className="sales_executive_name">
 {selectedProjects[0].salesName}                  </a>
                  <p>Sales Executive</p>
                  <ul className="d-flex flex-wrap justify-content-center">
                    <li>
                      <a href="#">
                        <i className="fab fa-facebook-f" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i
                          className="fab fa-linkedin-in"
                          aria-hidden="true"
                        ></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-instagram" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Projectsdetails;
