import React from "react";
import Feedback from "../sections/feedback/Feedback";
import Aboutonesec from "../sections/aboutfirstsection/Aboutonesec";
import Professionals from "../sections/meettherealityprofessionals/Professionals";
import Partners from "../sections/partners/Partners";
import aboutbg from "./aboutimages/inau1.jpg";
import Testimonials from "../sections/testimonials/Testimonials";
import { Link } from "react-router-dom";

const AboutUS = () => {
  return (
    <div style={{ marginTop: "110px" }}>
      <section
        className="breadcrumbs"
        style={{ background: `url(${aboutbg})`, objectFit: "cover" }}
      >
        <div className="breadcrumbs_overly">
          <div className="container">
            <div className="row">
              <div className="col-12 justify-content-center">
                <div
                  className="breadcrumb_text wow fadeInUp"
                  data-wow-duration="1.5s"
                >
                  <h1>About us</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Aboutonesec /> */}
      <Partners />

      <section className="     ">
        <div className="container">
          <div className="row justify-content-center">
            <div
              className="col-xxl-9 col-xl-6 wow fadeInUp"
              data-wow-duration="1.5s"
            >
              <div className="section_heading mb_50">
                <h2>Amenities</h2>
              </div>
            </div>
          </div>
          <div className="row wow fadeInUp" data-wow-duration="1.5s">
            <div className="col-xl-12">
              <ul className="single_amenites d-flex flex-wrap justify-content-center">
                <li>
                  <a>
                    <span>
                      <img
                        src="assets/images/amenities_img_1.png"
                        alt="img"
                        className="img-fluid w-100"
                      />
                    </span>
                    Smart TV
                  </a>
                </li>
                <li>
                  <a>
                    <span>
                      <img
                        src="assets/images/amenities_img_4.png"
                        alt="img"
                        className="img-fluid w-100"
                      />
                    </span>
                    Swimming Pool
                  </a>
                </li>
                <li>
                  <a>
                    <span>
                      <img
                        src="assets/images/amenities_img_3.png"
                        alt="img"
                        className="img-fluid w-100"
                      />
                    </span>
                    Elevator
                  </a>
                </li>
                <li>
                  <a>
                    <span>
                      <img
                        src="assets/images/amenities_img_4.png"
                        alt="img"
                        className="img-fluid w-100"
                      />
                    </span>
                    Walk In Closets
                  </a>
                </li>
                <li>
                  <a>
                    <span>
                      <img
                        src="assets/images/amenities_img_5.png"
                        alt="img"
                        className="img-fluid w-100"
                      />
                    </span>
                    Solar Panel
                  </a>
                </li>
                <li>
                  <a>
                    <span>
                      <img
                        src="assets/images/amenities_img_11.png"
                        alt="img"
                        className="img-fluid w-100"
                      />
                    </span>
                    CC Camera
                  </a>
                </li>
                <li>
                  <a>
                    <span>
                      <img
                        src="assets/images/amenities_img_6.png"
                        alt="img"
                        className="img-fluid w-100"
                      />
                    </span>
                    Air Conditioner
                  </a>
                </li>
                <li>
                  <a>
                    <span>
                      <img
                        src="assets/images/amenities_img_7.png"
                        alt="img"
                        className="img-fluid w-100"
                      />
                    </span>
                    Fireplace
                  </a>
                </li>
                <li>
                  <a>
                    <span>
                      <img
                        src="assets/images/amenities_img_12.png"
                        alt="img"
                        className="img-fluid w-100"
                      />
                    </span>
                    Garage
                  </a>
                </li>
                <li>
                  <a>
                    <span>
                      <img
                        src="assets/images/amenities_img_8.png"
                        alt="img"
                        className="img-fluid w-100"
                      />
                    </span>
                    Fireplace
                  </a>
                </li>
                <li>
                  <a>
                    <span>
                      <img
                        src="assets/images/amenities_img_10.png"
                        alt="img"
                        className="img-fluid w-100"
                      />
                    </span>
                    Garden
                  </a>
                </li>
                <li>
                  <a>
                    <span>
                      <img
                        src="assets/images/amenities_img_9.png"
                        alt="img"
                        className="img-fluid w-100"
                      />
                    </span>
                    Jacuzzi
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="row wow fadeInUp" data-wow-duration="1.5s">
            <div className="col-xl-12">
              <div className="amenities_area_btn mt_50">
                <Link to="/projects" className="common_btn_2">
                  Explore property<i className="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section
        className="agent_area agent_area_2 pt_115  xs_pt_95"
        style={{ padding: "40px 0" }}
      >
        <div className="container">
          <div
            className="row wow fadeInUp justify-content-center"
            data-wow-duration="1.5s"
          >
            <div className="col-xl-7">
              <div className="section_heading  mb_25">
                <h2>Our Agents</h2>
              </div>
            </div>
          </div>
          <div className="row"></div>
        </div>
      </section> */}
      <Professionals />

      <Testimonials />
      {/* <Feedback /> */}
    </div>
  );
};

export default AboutUS;
