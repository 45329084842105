import React from "react";
import prof1 from "./img/professional1.jpg";
import prof2 from "./img/professional2.jpg";
import prof3 from "./img/prof3.jpg";
import prof4 from "./img/userimg.jpg";
import prof5 from  './img/ankush.jpeg';
import prof6 from  './img/ganshyam (1).jpeg';
import prof7 from  './img/rahul.jpeg';
import prof8 from './img/vicky.jpeg'

const Professionals = () => {
  const obj = [
    {
      id: 1,
      imgpath: prof1,
      imgname: "Rahul Bagde",
      profession: "Marketing Manager",
      foneno: "+917777777777",
      email: "example@gmail.com",
    },
    {
      id: 2,
      imgpath: prof2,
      imgname: "Adarsh Lode",
      profession: "Marketing Manager",
      foneno: "+917777777777",
      email: "example@gmail.com",
    },
    {
      id: 3,
      imgpath: prof3,
      imgname: "Nashim Alam",
      profession: "Marketing Manager",
      foneno: "+917777777777",
      email: "example@gmail.com",
    },
    {
      id: 4,
      imgpath: prof7,
      imgname: "Rahul patle",
      profession: "Marketing Manager",
      foneno: "+917777777777",
      email: "example@gmail.com",
    },
    {
      id: 5,
      imgpath: prof6,
      imgname: "Ghanshyam Dhole",
      profession: "Marketing Manager",
      foneno: "+917777777777",
      email: "example@gmail.com",
    },
    {
      id: 6,
      imgpath: prof8,
      imgname: "Vicky Niwal",
      profession: "Marketing Manager",
      foneno: "+917777777777",
      email: "example@gmail.com",
    },
    {
      id: 7,
      imgpath: prof5,
      imgname: "Ankush pawar",
      profession: "Marketing Manager",
      foneno: "+917777777777",
      email: "example@gmail.com",
    },
  ];
  return (
    <>
      <section class="agent_2  " style={{ paddingTop: "60px" }}>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-7 wow fadeInUp" data-wow-duration="1.5s">
              <div class="section_heading mb_25">
                <h2>Meet the Reality Professionals</h2>
              </div>
            </div>
          </div>
          <div class="row">
            {obj.map((i, index) => (
              <div
                class="col-xl-3 col-md-6 col-lg-4  wow fadeInUp"
                style={{ padding: "10px" }}
                data-wow-duration="1.5s"
              >
                <div class="single_agent_2">
                  <div class="agent_2_img">
                    <img
                      src={i.imgpath}
                      alt="img"
                      style={{ objectFit: "cover" }}
                      class="img-fluid w-100"
                    />
                  </div>
                  <div class="agent_2_text d-flex flex-wrap justify-content-between">
                    <div class="agent_2_text_left">
                      <a class="item_title"> {i.imgname}</a>
                      <p>{i.profession}</p>
                    </div>
                    <div class="agent_2_text_right">
                      <a href="#">
                        <i class="fas fa-comment-dots"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Professionals;
