import React from "react";
import Latestproperties from "../sections/Latestproperties";
import Happycustomer from "../sections/happycustomer/Happycustomer";
import MultiCarousel from "../slider/Swipper";
import { Link } from "react-router-dom";
import Professionals from "../sections/meettherealityprofessionals/Professionals";
import Amentities from "../sections/amentities/Amentities";
import Whychooseus from "../sections/whychooseus/Whychooseus";
import aboutimg from "./aboutimages/ab1.jpg";
import Completedprojects from "../sections/exploreourprojects/Completedprojects";
import Aboutonesec from "../sections/aboutfirstsection/Aboutonesec";
const Home = () => {
  return (
    <>
      <MultiCarousel />

      <Aboutonesec />

      <Amentities />

      <section className="latest_property  pt-5  ">
        <div className="container">
          <div className="row wow fadeInUp" data-wow-duration="1.5s">
            <div className="col-xl-12">
              <div className="section_heading justify-content-center  mb_25">
                <h2>Latest Properties</h2>
              </div>
            </div>
          </div>

          <div className="row ">
            {" "}
            <Latestproperties />
          </div>
        </div>
      </section>
      <Completedprojects />
      <Professionals />

      <Whychooseus />
    </>
  );
};

export default Home;
