import React from "react";
import img1 from "./images/nishita.svg";
import img2 from "./images/arjun.svg";
import img3 from "./images/roshni.svg";

const Testimonials = () => {
  return (
    <section className="" style={{ padding: "40px 0" }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            {" "}
            <div
              style={{ marginBottom: "40px" }}
              class="single_property_details mt_25 wow fadeInUp"
              data-wow-duration="1.5s"
            >
              <h2 className="text-center">Testimonials</h2>

              {/* <div class=" apartment_review">
                <div class="row align-items-center">
                  <div class="col-xl-6">
                    <div class="apartment_review_counter">
                      <h3>5.0</h3>
                      <p>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                      </p>
                      <span>(2 Customer Reviews)</span>
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="review_progressbar">
                      <div class="single_bar">
                        <p>5 Star</p>
                        <div id="bar1" class="barfiller">
                          <div class="tipWrap">
                            <span class="tip"></span>
                          </div>
                          <span class="fill" data-percentage="70"></span>
                        </div>
                      </div>
                      <div class="single_bar">
                        <p>4 Star</p>
                        <div id="bar2" class="barfiller">
                          <div class="tipWrap">
                            <span class="tip"></span>
                          </div>
                          <span class="fill" data-percentage="15"></span>
                        </div>
                      </div>
                      <div class="single_bar">
                        <p>3 Star</p>
                        <div id="bar3" class="barfiller">
                          <div class="tipWrap">
                            <span class="tip"></span>
                          </div>
                          <span class="fill" data-percentage="10"></span>
                        </div>
                      </div>
                      <div class="single_bar">
                        <p>2 Star</p>
                        <div id="bar4" class="barfiller">
                          <div class="tipWrap">
                            <span class="tip"></span>
                          </div>
                          <span class="fill" data-percentage="5"></span>
                        </div>
                      </div>
                      <div class="single_bar">
                        <p>1 Star</p>
                        <div id="bar5" class="barfiller">
                          <div class="tipWrap">
                            <span class="tip"></span>
                          </div>
                          <span class="fill" data-percentage="0"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div class="apartment_review_area">
                <div class="single_review">
                  <div class="single_review_img">
                    <img src={img1} alt="img" class="img-fluid w-100" />
                  </div>
                  <div class="single_review_text">
                    <h3>
                      Nishita Mehra
                      <span>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                      </span>
                    </h3>
                    <h6>July 28, 2023</h6>
                    <p>
                      "Siddhivinayak Realities Developers is synonymous with
                      innovation. Their projects showcase modern design and
                      thoughtful amenities."
                    </p>
                  </div>
                </div>
                <div class="single_review">
                  <div class="single_review_img">
                    <img src={img2} alt="img" class="img-fluid w-100" />
                  </div>
                  <div class="single_review_text">
                    <h3>
                      Arjun Pandey
                      <span>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                      </span>
                    </h3>
                    <h6>February 24, 2022</h6>
                    <p>
                      "Siddhivinayak Realities delivered a seamless experience.
                      Their attention to detail and customer-centric approach
                      set them apart."
                    </p>
                  </div>
                </div>
                <div class="single_review">
                  <div class="single_review_img">
                    <img src={img3} alt="img" class="img-fluid w-100" />
                  </div>
                  <div class="single_review_text">
                    <h3>
                      Roshni Kapoor{" "}
                      <span>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                      </span>
                    </h3>
                    <h6>May 27, 2021</h6>
                    <p>
                      "Siddhivinayak Realities Developers exceeded our
                      expectations. Their commitment to quality and
                      sustainability is commendable."
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
