import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import AboutUS from "./pages/AboutUS";
import ContactUS from "./pages/ContactUS";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Projects from "./pages/Projects";
import { useEffect } from "react";
import Projectsdetails from "./sections/exploreourprojects/Projectsdetails";
import GoToTop from "./sections/goToTop/GoToTop";
function App() {
  const Scrolltotop = () => {
    const location = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);
    return null;
  };


  return (
    <div  style={{background:'rgb(235, 244, 254)'}}>
      <BrowserRouter>
        <Header />
        <Scrolltotop />
        <Routes>
          <Route path="" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/aboutus" element={<AboutUS />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/projects/:id" element={<Projectsdetails />} />
          <Route path="/contactus" element={<ContactUS />} />
        </Routes>
        <Footer />
        <GoToTop />
      </BrowserRouter>
    </div>
  );
}

export default App;
