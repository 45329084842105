import React, { useState } from "react";
import shrinagariphase1 from "../sections/exploreourprojects/images/shrinagariphase1.jpg";
import ektanagari from "../sections/exploreourprojects/images/ektanagari.jpg";
import shrinagariphase2 from "../sections/exploreourprojects/images/shrinagariphase2.jpg";
import swapnanagari from "../sections/exploreourprojects/images/swapnanagari.jpg";
import shrinagariphase3 from "../sections/exploreourprojects/images/shrinagariphase3.jpg";
import madhavnagari from "../sections/exploreourprojects/images/madhavnagari.jpg";
import projectsbg from "./aboutimages/real.webp";
import { useNavigate } from "react-router-dom";
import Swipper from "../slider/Swipper";
import ProjecsSlider from "../slider/ProjectsSlider";

const Projects = () => {
  const navigate = useNavigate();

  const [selectedImage, setSelectedImage] = useState(null);

  const contonj = [
    {
      id: "1",
      imgpath: shrinagariphase1,
      imgname: "Shri Sai Nagari",
      phase: "Phase 1",
      address: `AT Mouza Panjarakate, Tah-Katol, Dist-Nagpur`,
    },
    {
      id: "2",
      imgpath: swapnanagari,
      imgname: "Swapna Nagari",
      phase: "Phase 1",
      address: `AT Mouza Sonegaon (Kondhali), Tah-Katol, Dist-Nagpur`,
    },
    {
      id: "3",
      imgpath: ektanagari,
      imgname: "Ekta Nagari",
      phase: "Phase 1",
      address: `AT Mouza Katepanjara, Tah-Katol, Dist-Nagpur`,
    },
    {
      id: "4",
      imgpath: shrinagariphase2,
      imgname: "Shri Nagari",
      phase: "Phase 2",
      address: `AT Mouza Khapa (Sonar), Tah-Katol, Dist-Nagpur`,
    },
    {
      id: "5",
      imgpath: shrinagariphase3,
      imgname: "Shri Nagari",
      phase: "Phase 3",
      address: `AT Mouza Khapasonar, Tah-Katol, Dist-Nagpur`,
    },
    {
      id: "6",
      imgpath: madhavnagari,
      imgname: "Madhav Nagari",
      phase: "Phase 1",
      address: `AT Mouza Salai Khurdh, Tah-Katol, Dist-Nagpur`,
    },
  ];

  const openDetailsPage = (id) => {
    navigate(`/projects/${id}`);
  };

  return (
    <>
      <section
        className="breadcrumbs"
        // style={{ background: `url(${projectsbg})` }}
      >
        <ProjecsSlider />
      </section>
      <section className="blog_area pt_95 xs_pt_75 pb_120 xs_pb_100">
        <div className="container">
          <div className="row">
            {contonj.map((i) => (
              <div
                style={{ padding: "15px" }}
                className="col-xl-4 col-md-6 wow fadeInUp"
                data-wow-duration="1.5s"
                key={i.id}
              >
                <div className="single_blog">
                  <div className="blog_img">
                    <div onClick={() => openDetailsPage(i.id)}>
                      <img
                        src={i.imgpath}
                        alt="img"
                        className="img-fluid w-100"
                      />
                    </div>
                  </div>
                  <div className="blog_text">
                    <div className="item_title">{i.imgname} </div>
                    <div style={{ display: "flex" }}>
                      <i
                        className="fas fa-map-marker-alt"
                        aria-hidden="true"
                      ></i>
                      <p style={{ marginLeft: "10px" }}> {i.address}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Projects;
