import React from "react";
import { Link } from "react-router-dom";
import shrinagariphase1 from "./images/shrinagariphase1.jpg";
import swapnanagari from "./images/swapnanagari.jpg";

import ektanagari from "./images/ektanagari.jpg";
import shrinagariphase2 from "./images/shrinagariphase2.jpg";
import shrinagariphase3 from "./images/shrinagariphase3.jpg";
import madhavnagari from "./images/madhavnagari.jpg";
const Completedprojects = () => {
  const contonj = [
    {
      id: "1",
      imgpath: shrinagariphase1,
      imgname: "Shri Sai Nagari",
      phase: "Phase 1",
      address: `AT Mouza Panjarakate, Tah-Katol, Dist-Nagpur`,
    },
    {
      id: "2",
      imgpath: swapnanagari,
      imgname: "Swapna Nagari",
      phase: "Phase 1",
      address: `AT Mouza Sonegaon, Tah-Katol, Dist-Nagpur`,
    },
    {
      id: "3",
      imgpath: ektanagari,
      imgname: "Ekta Nagari",
      phase: "Phase 1",
      address: `AT Mouza Katepanjara, Tah-Katol, Dist-Nagpur`,
    },
    {
      id: "4",
      imgpath: shrinagariphase2,
      imgname: "Shri Nagari",
      phase: "Phase 2",
      address: `AT Mouza Khapa (Sonar), Tah-Katol, Dist-Nagpur`,
    },
    {
      id: "5",
      imgpath: shrinagariphase3,
      imgname: "Shri Nagari",
      phase: "Phase 3",
      address: `AT Mouza Khapasonar, Tah-Katol, Dist-Nagpur`,
    },
    {
      id: "6",
      imgpath: madhavnagari,
      imgname: "Madhav Nagari",
      phase: "Phase 1",
      address: `AT Mouza Salai Khurdh, Tah-Katol, Dist-Nagpur`,
    },
  ];
  return (
    <section className="explore_listing     " style={{}}>
      <div className="container">
        <div className="row wow fadeInUp " data-wow-duration="1.5s">
          <div className="col-xl-12">
            <div className="section_heading justify-content-center  mb_25">
              <h2> Our Completed Projects</h2>
            </div>
          </div>
        </div>
        <div className="row">
          {contonj.map((i) => (
            <div
              key={i.id}
              className="col-xl-4 col-sm-6 wow fadeInUp"
              data-wow-duration="1.5s"
            >
              <div className="explore_listing_item">
                <Link to="/projects" href="#" className="img">
                  <img
                    src={i.imgpath}
                    alt="explore"
                    className="img-fluid w-100"
                  />
                </Link>
                <div className="text">
                  <Link to="/projects">
                    <div className="item_title">{i.imgname} </div>
                  </Link>

                  <div style={{ display: "flex" }}>
                    <i className="fas fa-map-marker-alt" aria-hidden="true"></i>
                    <p style={{ marginLeft: "10px", marginTop: "0" }}>
                      {" "}
                      {i.address}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Completedprojects;
