import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./happcustomer.css";

const Happycustomer = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const imgobj = [
    {
      id: 1,
      img: "assets/images/testimonial_2_img_1.png",
      name: "Douglas Lyphe",
      para: "Operations Manager - Static Mania ",
      content:
        " Cras tellus neque, condimentum in nulla sed, ultrices dictum  nisi. Ut vitae ex eu purus egestas ornare. Praesent eleifend  leo dolor, non pharetra libero facilisis pulvinar. Quisque  consectetur accumsan lacus eu bibendum.Cras sagittis magna  ex, ultrices dictum.",
    },
    {
      id: 2,
      img: "assets/images/testimonial_2_img_1.png",
      name: "Douglas Lyphe",
      para: "Operations Manager - Static Mania ",
      content:
        " Cras tellus neque, condimentum in nulla sed, ultrices dictum  nisi. Ut vitae ex eu purus egestas ornare. Praesent eleifend  leo dolor, non pharetra libero facilisis pulvinar. Quisque  consectetur accumsan lacus eu bibendum.Cras sagittis magna  ex, ultrices dictum.",
    },
    {
      id: 3,
      img: "assets/images/testimonial_2_img_1.png",
      name: "Douglas Lyphe",
      para: "Operations Manager - Static Mania ",
      content:
        " Cras tellus neque, condimentum in nulla sed, ultrices dictum  nisi. Ut vitae ex eu purus egestas ornare. Praesent eleifend  leo dolor, non pharetra libero facilisis pulvinar. Quisque  consectetur accumsan lacus eu bibendum.Cras sagittis magna  ex, ultrices dictum.",
    },
  ];

  return (
    <Slider {...settings}>
      {imgobj.map((i) => (
        <div className="col-12 wow fadeInUp" data-wow-duration="1.5s">
          <div className="testimonial_item_2">
            <div className="row">
              <div className="col-xxl-4 col-12 col-sm-5 col-md-5">
                <div className="testimonial_item_2_img">
                  <img
                    src={i.img}
                    alt="testimonial"
                    className="img-fluid w-100"
                  />
                </div>
              </div>
              <div className="col-xxl-8 col-12 col-md-7">
                <div className="testimonial_item_2_text">
                  <span className="testimonial_2_rating">
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                    <i className="fas fa-star"></i>
                  </span>
                  <p className="testimonial_2_description">{i.content}</p>
                  <h3 className="testimonial_2_title">{i.name}</h3>
                  <p className="testimonial_2_designation">{i.para} </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default Happycustomer;
