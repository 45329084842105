import React from "react";
import "./amentities.css";
import about from "./about_1.jpg";
import attractive from "./images/attractive.webp";
import garden from "./images/garden.png";
import loan from "./images/loan.jpg";
import expertadvice from "./images/experts.jpg";
import compoundwall from "./images/compoundwall.webp";
import verifiedagents from "./images/agents.png";

const Amentities = () => {
  const obj = [
    {
      id: 1,
      imgpath: attractive,
      title: "Attractive Locations",
      para: " Experience convenience and luxury at its finest location.",
    },
    {
      id: 2,
      imgpath: garden,
      title: "Garden",
      para: "Lush garden oasis invites tranquility and natural beauty.",
    },
    {
      id: 3,
      imgpath: loan,
      title: " Loan Facilities",
      para: "From mortgage advice to securing competitive interest rates, we ensure a seamless process from start to finish",
    },
    {
      id: 4,
      imgpath: expertadvice,
      title: "Expert Advice",
      para: "Seek expert advice from seasoned professionals for informed property decisions.",
    },
    {
      id: 5,
      imgpath: compoundwall,
      title: "Fencing Compound Wall",
      para: "Open space enclosed with a fencing compound wall for security.",
    },
    {
      id: 6,
      imgpath: verifiedagents,
      title: "Verified Agents",
      para: "Trust verified agents for reliable property transactions.",
    },
  ];
  return (
    <>
      {" "}
      <section className="   " style={{ background: "#ebf4fe" }}>
        <div className="container">
          <div className="row">
            {" "}
            <div className="col-md-12 text-center  ">
              <div className="section_heading mt_50 mb_50">
                <h2>Facilities</h2>
              </div>{" "}
            </div>
          </div>
          <div className="row flexjustify">
            {obj.map((i, index) => (
              <div className="card mb-3 adjustwidth">
                <div className="row g-0 ">
                  <div className="col-md-4">
                    <div className="imgcontpar">
                      <img
                        src={i.imgpath}
                        className="img-fluid rounded-start mainimgclass"
                        alt="..."
                      />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="card-body">
                      <h5 className="card-title">{i.title}</h5>
                      <p className="card-text">{i.para}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}{" "}
          </div>
        </div>
      </section>
    </>
  );
};

export default Amentities;
