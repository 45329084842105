import React from "react";
import img from "./about_1.jpg";
import img1 from "./images/transparecy.jpg";
import img2 from "./images/why2.jpg";
import img3 from "./images/why3.jpg";
import img4 from "./images/why4.jpg";
import img5 from "./images/why5.jpg";
import img6 from "./images/why6.jpg";

const Whychooseus = () => {
  const contentobj = [
    {
      id: "1",
      imgpath: img1,
      headings: "Transparency",
      para: "We pride ourselves on being transparent with our clients and honest communication on every step.",
    },
    {
      id: "2",
      imgpath: img2,
      headings: "Hard Work",
      para: "We are dedicated to our clients' success, tirelessly putting in the hard work necessary to exceed their expectations.",
    },
    {
      id: "3",
      imgpath: img3,
      headings: "Home Loans",
      para: "Unlock your dream home with flexible and affordable home loan solutions tailored to your needs.",
    },
    {
      id: "4",
      imgpath: img4,
      headings: "Legality",
      para: "Adherence to legal norms ensures transparency, security, and trust in every property transaction we undertake.",
    },
    {
      id: "5",
      imgpath: img5,
      headings: "Clear Title Property",
      para: "Guaranteeing clear titles, our properties embody a promise of transparent ownership and hassle-free transactions.",
    },
    {
      id: "6",
      imgpath: img6,
      headings: "Amenities",
      para: "Elevate your lifestyle with thoughtfully curated amenities, enhancing the living experience within  communities.",
    },
  ];
  return (
    <section
      className="blog_area  xs_pt_95  xs_pb_100"
      style={{
        background: "#ebf4fe",

        paddingTop: "80px",
        paddingBottom: "70px",
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 wow fadeInUp" data-wow-duration="1.5s">
            <div className="section_heading mb_15">
              <h2>Why Choose Us</h2>
            </div>
          </div>
        </div>
        <div className="row">
          {contentobj.map((i) => (
            <div
              key={i.id}
              className="col-xl-4 col-md-6 wow fadeInUp p-3"
              data-wow-duration="1.5s"
            >
              <div className="single_blog">
                <a href="#" className="blog_img">
                  <img src={i.imgpath} alt="img" className="img-fluid w-100" />
                </a>
                <div className="blog_text">
                  <h6 className="pb-1">{i.headings}</h6>
                  <p>{i.para}</p>
                  {/* <a className="item_title" href="#">
                    {i.para}{" "}
                  </a> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Whychooseus;
