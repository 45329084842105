import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./latestproperties.css";
import hanuman from "./latestpropertiesimages/hanumannagari.jpg";
import ngp from "./latestpropertiesimages/ngpmale.webp";

const Latestproperties = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const imageobj = [
    {
      id: "1",
      path: hanuman,
      plotname: "Madhav Nagari",
      address: "Mouza-Salai Khurdh, Tah.-Katol, Dist.-Nagpur",
    },
    {
      id: "2",
      path: "https://5.imimg.com/data5/SELLER/Default/2022/6/SU/PF/JV/24955460/land-development-500x500.jpg",
      plotname: "Shri Sai Nagari",
      address: "Mouza-Dhudhala, Khurdh, Tah.-Katol, Dist.-Nagpur",
    },
    {
      id: "3",
      path: ngp,
      plotname: "Madhav Nagari",
      address: "Mouza-Salai Khurdh, Tah.-Katol, Dist.-Nagpur",
    },
  ];
  return (
    <Carousel
      responsive={responsive}
      infinite={true}
      draggable={true}
      swipeable={true}
      keyBoardControl={true}
      autoPlay={true}
      autoPlaySpeed={3000}
    >
      {imageobj.map((i) => (
        <div key={i.id} className="col wow fadeInUp" data-wow-duration="1.5s">
          <div className="single_property single_property_3">
            <div className="single_property_img">
              <img src={i.path} alt="img" className="img-fluid w-100" />
            </div>
            <div className="single_property_text">
              <div className="single_property_top">
                <a className="item_title" href="#">
                  {i.plotname}
                </a>
                <p>
                  <i className="fas fa-map-marker-alt" aria-hidden="true"></i>
                  {i.address}{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export default Latestproperties;
