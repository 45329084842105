import React from "react";
import image1 from "./images/image1.jpg";
import image2 from "./images/madhavrao.jpg";

import "./partners.css";
const Partners = () => {
  return (
    <div div className="pt-5 pb-5">
      {" "}
      <section>
        <div className="container">
          <div className="row  colrevers">
            <div
              className="col conentpadding wow fadeInLeft"
              data-wow-duration="1.5s"
            >
              <h3 className="pt-3 pb-3">Mr. Madhorav Sadashiv Rane</h3>
              <p>
                Greetings, I am Madhorav Rane, a seasoned and passionate land
                developer deeply immersed in the transformative world of real
                estate. With a rich and diverse portfolio, I have actively
                contributed to shaping and crafting numerous land projects over
                the years. My journey in the industry has been defined by an
                unwavering commitment to delivering top-notch developments that
                endure the test of time. Rooted in a visionary approach, each
                project undertaken reflects my dedication to excellence, driven
                by a desire to create spaces that transcend expectations.
                Customer satisfaction is not just a goal but a guiding principle
                at the core of my work. I endeavor to infuse every development
                with a unique blend of innovation and reliability, ensuring that
                each endeavor becomes a testament to my commitment to quality.
              </p>
            </div>
            <div className="col p-3 wow fadeInLeft" data-wow-duration="1.5s">
              <div className="heightimage">
                {" "}
                <img
                  // id="partner1"
                  src={image2}
                  style={{
                    objectPosition: "top",
                    borderRadius: "20px",
                    // boxShadow: "13px 16px 6px #eee",
                  }}
                />
              </div>{" "}
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row flexcol">
            <div className="col p-3 wow fadeInRight" data-wow-duration="1.5s">
              <div className="heightimage">
                {" "}
                <img
                  // id="partner1"
                  src={image1}
                  style={{
                    borderRadius: "20px",
                    // boxShadow: "13px 16px 6px #000",
                  }}
                />
              </div>{" "}
            </div>
            <div
              className="col conentpadding wow fadeInRight"
              data-wow-duration="1.5s"
            >
              <h3 className="pt-3 pb-3">Mr. Manoj Vithoba Donadkar</h3>
              <p>
                Greetings, I'm Manoj Vithoba Donadkar, and I take pride in being
                a land developer with a passion for turning dreams into reality.
                In my journey, I've learned that real estate is not just about
                buildings—it's about creating vibrant living spaces that enhance
                the way we live. I've always believed in setting high standards,
                and my projects reflect this commitment to excellence. Each
                development is a carefully crafted blend of modern aesthetics,
                sustainable practices, and amenities that make a difference. My
                philosophy revolves around integrity, transparency, and putting
                customers at the center of everything we do. For me, real estate
                is not just a business; it's about shaping lifestyles and
                fostering communities. My experienced team works tirelessly to
                deliver projects that go beyond expectations, creating homes
                that stand as a testament to our vision. It's not just about
                constructing buildings; it's about creating environments where
                people can truly thrive.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Partners;
