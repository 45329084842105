import React from "react";
import { Link } from "react-router-dom";
import aboutimg from "../../pages/aboutimages/ab1.jpg";

const Aboutonesec = () => {
  return (
    <section
      className="about_2 mt_120 xs_mt_100 "
      style={{ paddingBottom: "40px" }}
    >
      <div className="container">
        <div className="row" style={{ padding: "0 25px" }}>
          <div
            className="col-xl-5  col-lg-6 wow fadeInLeft"
            data-wow-duration="1.5s"
          >
            <div className="about_2_img">
              <img
                src={aboutimg}
                alt="about images"
                className="img-fluid w-100"
              />
            </div>
          </div>
          <div
            className="col-xl-7 col-md-12 col-lg-6 wow fadeInRight p-3"
            data-wow-duration="1.5s"
          >
            <div className="about_2_text">
              <div className="section_heading section_heading_left mb_25">
                <h2>
                  <Link
                    to="/aboutus"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    About Us
                  </Link>
                </h2>
              </div>
              <p>
                "Siddhivinayak Land Developers is a trusted name in the realm of
                real estate, dedicated to transforming aspirations into tangible
                living spaces. With a rich history and a commitment to
                excellence, we embark on a journey to redefine the landscape,
                creating not just homes, but communities that resonate with
                quality and innovation. Our philosophy revolves around the core
                values of integrity, transparency, and customer-centricity.
                Guided by these principles, Siddhivinayak Land Developers crafts
                residential and commercial projects that reflect a harmonious
                blend of modern design, sustainable practices, and thoughtful
                amenities. At Siddhivinayak, we believe in shaping more than
                just structures; we aim to craft lifestyles. Our experienced
                team of professionals works tirelessly to deliver projects that
                not only meet but exceed the expectations of our valued
                customers. Each endeavor is a testament to our unwavering
                commitment to providing spaces that inspire and elevate the
                quality of life. Join us on a journey where innovation meets
                integrity, where dream homes become a reality, and where
                Siddhivinayak Land Developers stands as a symbol of trust and
                excellence in the world of real estate."
              </p>

              <Link
                style={{ top: "20px" }}
                to="/aboutus"
                className="common_btn mt-2"
              >
                Who We Are
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Aboutonesec;
