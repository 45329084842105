import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./swipper.css";
import hanuman from "./mainslider/hanumannagari.jpg";
import ngpmale from "./mainslider/ngpmale.webp";
import F1images from "../sections/exploreourprojects/images/F1images.jpg";
import F2images from "../sections/exploreourprojects/images/F2images.jpg";
import F3images from "../sections/exploreourprojects/images/F3images.jpg";
import F4images from "../sections/exploreourprojects/images/F4images.jpg";
import F5images from "../sections/exploreourprojects/images/F5images.jpg";
import F6images from "../sections/exploreourprojects/images/F6images.jpg";
import F7images from "../sections/exploreourprojects/images/F7images.jpg";
import F8images from "../sections/exploreourprojects/images/F8images.jpg";
import F9images from "../sections/exploreourprojects/images/F9images.jpg";

const MultiCarousel = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const imageobj = [
    {
      id: "2",

      location: "Ekta Nagari",
      path: F2images,
    },
    {
      id: "3",

      location: "Ekta Nagari",
      path: F4images,
    },
    {
      id: "4",
      location: "Shri Sai Nagari",
      path: F9images,
    },
    {
      id: "5",
      location: "Madhav Nagari",
      path: F7images,
    },
    {
      id: "5",
      location: "Shree  Nagari",
      path: F6images,
    },
  ];
  return (
    <Carousel
      responsive={responsive}
      infinite={true}
      draggable={true}
      swipeable={false}
      keyBoardControl={false}
      autoPlay={true}
      //  autoPlaySpeed={2000}
      arrows={false}
    >
      {imageobj.map((i) => (
        <div key={i.id} className="mainslparent">
          <img
            src={i.path}
            style={{ objectFit: "cover", marginTop: "20px" }}
            className="myimg"
            alt="image not found"
          />
          <div
            className="sloverlay"
            style={{ height: "90vh", opacity: "-0.06" }}
          ></div>
          {/* <p className="imagetextslider">{i.location}</p> */}
        </div>
      ))}
    </Carousel>
  );
};

export default MultiCarousel;
