import React, { useState  , useRef} from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from '@emailjs/browser';

const ContactUS = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactno, setContactNo] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [loader , setLoader] = useState(false);

  const form = useRef();


  const sendEmail = (e) => {
    e.preventDefault();
    setLoader(true);
    if(!email && !name && !contactno && !message && !subject ){
      toast.error("Please fill out all fields" , { autoClose: 1500 });
      setLoader(false);
       return ;
    }

    emailjs
      .sendForm('service_uxggv9o', 'template_vsrdhc8', form.current, {
        publicKey: 'wZs7KW8Zux3WrlXsA',
      })
      .then(
        () => {
          toast.success("Email sent successfully!" , { autoClose: 1500 });

          setName("");
          setEmail("");
          setContactNo("");
          setMessage("");
          setSubject("");
          ResetthePage();
          setLoader(false);

        },
        (error) => {
          console.log('FAILED...', error.text);
          toast.error("Failed to send email. Please try again." , { autoClose: 1500 });
          setLoader(false);

        },
      );
  };


  const ResetthePage=()=>{
    setTimeout(()=>{
      window.location.reload();
    },1000)
   
  }



  return (
    <>
      <ToastContainer theme="dark" />
      <section
        className="breadcrumbs"
        style={{ background: "url(assets/images/breadcrumbs_bg.jpg)" }}
      >
        <div className="breadcrumbs_overly">
          <div className="container">
            <div className="row">
              <div className="col-12 justify-content-center">
                <div
                  className="breadcrumb_text wow fadeInUp"
                  data-wow-duration="1.5s"
                >
                  <h1>Contact Us</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="contact_area pt_120 xs_pt_100 xs_pb_100">
        <div className="container">
          <div
            className="row justify-content-between  "
            style={{ padding: "0 25px" }}
          >
            <div
              className="col-xxl-4 col-lg-5 wow fadeInLeft"
              data-wow-duration="1.5s"
            >
              <div className="contact_address">
                <h4>Get In Touch</h4>
                <ul>
                  <li>
                    <span>
                      <img
                        src="assets/images/location.png"
                        alt="icon"
                        className="img-fluid w-100"
                      />
                    </span>
                    <div className="contact_address_text">
                      <p>Office address</p>
                      <a className="item_title" href="#">
                        202 (2nd) Jalaram mangalam,
                      </a>
                      <a className="item_title" href="#">
                        Hingna Rd, opp.tactor company,
                      </a>
                      <a className="item_title" href="#">
                        Nagpur, Maharashtra 440016
                      </a>
                    </div>
                  </li>
                  <li>
                    <span>
                      <img
                        src="assets/images/call.png"
                        alt="icon"
                        className="img-fluid w-100"
                      />
                    </span>
                    <div className="contact_address_text">
                      <p>Request a call back</p>
                      <a className="item_title" href="#">
                        +91 7841804871
                      </a>
                    </div>
                  </li>
                  <li>
                    <span>
                      <img
                        src="assets/images/massage_3.png"
                        alt="icon"
                        className="img-fluid w-100"
                      />
                    </span>
                    <div className="contact_address_text">
                      <p>Email with us</p>
                      <a className="item_title" href="#">
                        siddhivinayakrealities23@gmail.com
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className="col-xxl-7 col-lg-7 wow fadeInRight"
              data-wow-duration="1.5s"
            >
           <form ref={form} onSubmit={sendEmail}>
                <div className="row">
                  <div className="col-md-6 col-lg-12 col-xl-6">
                    <div className="contact_input">
                      <input
                        type="text"
                        placeholder="Your Name"
                        value={name}
                        name="from_name"
                        onChange={(e) => setName(e.target.value)}
                      />
                      <span className="contact_input_icon">
                        <img
                          src="assets/images/user_icon_3.png"
                          alt="icon"
                          className="img-fluid w-100"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-12 col-xl-6">
                    <div className="contact_input">
                      <input
                        type="email"
                        placeholder="Your Email"
                        value={email}
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <span className="contact_input_icon">
                        <img
                          src="assets/images/massage_4.png"
                          alt="icon"
                          className="img-fluid w-100"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-12 col-xl-6">
                    <div className="contact_input">
                      <input
                        type="number"
                        placeholder="Phone Number"
                        value={contactno}
                        name="number"
                        onChange={(e) => setContactNo(e.target.value)}
                      />
                      <span className="contact_input_icon">
                        <img
                          src="assets/images/call_2.png"
                          alt="icon"
                          className="img-fluid w-100"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-12 col-xl-6">
                    <div className="contact_input">
                      <select
                        style={{
                          width: "100%",
                          height: "50px",
                          border: "1px solid #ccc",
                          marginBottom: "25px",
                        }}
                        className="select_2"
                        value={subject}
                        name="service"
                        onChange={(e) => setSubject(e.target.value)}
                      >
                        <option value="">Select Subject</option>
                        <option value="Property Inquiry">Property Inquiry</option>
<option value="Property Purchase">Property Purchase</option>
<option value="Property Sale">Property Sale</option>

                      </select>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="contact_input">
                      <textarea
                        rows="6"
                        placeholder="Write Message..."
                        value={message}
                        name="message"
                        onChange={(e) => setMessage(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="contact_input">
                     {
                      loader ?<div className="spinner-container">
                      <div className="spinner"></div>
                    </div>:

                      <button
                      type="submit"
                      className="common_btn"
                    >
                      Send Message
                    </button>
                     }
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div
              className="single_property_details mt_25 wow fadeInUp"
              data-wow-duration="1.5s"
            >
              <h4>Map Location</h4>
              <div className=" apertment_map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3721.9781724068416!2d79.00608977525893!3d21.113436380557197!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd495bf046d6ec1%3A0x8c952ff3323057b7!2sJalaram%20Mangalam%20-%20Golechha%20Housing%20%26%20Infrastructure%20Pvt%20Ltd.!5e0!3m2!1sen!2sin!4v1708948946148!5m2!1sen!2sin"
                  width="600"
                  height="450"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUS;
